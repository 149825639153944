import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection pt-4 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="subtitle">{t("OUR STORY")}</h3>
              <p className="">{data?.about_company}</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eos
                sint id eaque ducimus repellat enim ad, non accusantium, nulla
                impedit autem rerum sequi veniam quos ipsum alias nisi tenetur.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga
                dolore quos dolores itaque eius illo veniam labore odio sapiente
                exercitationem distinctio temporibus, impedit amet dignissimos
                quasi aut et voluptates nesciunt.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga
                dolore quos dolores itaque eius illo veniam labore odio sapiente
                exercitationem distinctio temporibus, impedit amet dignissimos
                quasi aut et voluptates nesciunt.
              </p>
              <p>{t("about-1")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
