import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import About from "../../components/about/About";

function AboutPage({ setShow }) {
  return (
    <>
      <Helmet>
        <title>About Us | Ksquare Infratech & Interior Pvt Ltd</title>
        <meta name="keyword" content="Ksquare Infratech & Interior Pvt Ltd" />
        <meta
          name="description"
          content="Ksquare Infratech & Interior Pvt Ltd"
        />
      </Helmet>
      <About />
    </>
  );
}

export default AboutPage;
