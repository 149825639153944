import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Contact from "../../components/contact/Contact";

function ContactPage({ setShow }) {
  return (
    <>
      <Helmet>
        <title>Contact Us | Ksquare Infratech & Interior Pvt Ltd</title>
        <meta name="keyword" content="Ksquare Infratech & Interior Pvt Ltd" />
        <meta
          name="description"
          content="Ksquare Infratech & Interior Pvt Ltd"
        />
      </Helmet>
      <Contact />
    </>
  );
}

export default ContactPage;
