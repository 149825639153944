import React, { useEffect, useState } from "react";
import {
  useGetCategoriesQuery,
  useGetMenuListQuery,
} from "../../products/productSlice";

import "./ProductsCategory.css";
import axios from "axios";
import Slider from "react-slick";

import service1 from "../../../assets/img/rki/network/cabinet.jpg";
import service2 from "../../../assets/img/rki/network/chair.jpg";
import service3 from "../../../assets/img/rki/network/lamp.jpg";
import service4 from "../../../assets/img/rki/network/sofa.jpg";
import service5 from "../../../assets/img/rki/network/stool.jpg";
import service6 from "../../../assets/img/rki/network/table.jpg";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { base_url } from "../../../server";

// const categoriesItems = [
//   { id: "1", imgUrls: service1, title: "GEAR & TARVEL" },
//   { id: "2", imgUrls: service2, title: "BOTTLE FEEDING" },
//   { id: "3", imgUrls: service3, title: " BIBS & PACIFIERS" },
//   { id: "4", imgUrls: service4, title: "BATH TIMES" },
//   { id: "5", imgUrls: service5, title: "CLEAN - DISHES" },
//   { id: "6", imgUrls: service6, title: "DISHES & FOOD" },
// ];

function ProductsCategory() {
  // const { isLoading, error } = useGetCategoriesQuery();

  const [categoriesDatas, setCateData] = useState(null);
  const baseUrl = base_url();
  const getcateData = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/public`, {
        withCredentials: true,
      });
      setCateData(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getcateData();
  }, []);

  const settings = {
    speed: 500,
    slidesToShow: 5, // Number of items to show on desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Screen size for tablets
        settings: {
          slidesToShow: 7, // Number of items to show on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Screen size for mobile devices
        settings: {
          slidesToShow: 3, // Number of items to show on mobile
          slidesToScroll: 1,
          // arrows: false,
        },
      },
    ],
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="serviceList sectionPD pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content">
                <h3> {t("Shop by category")}</h3>
                <p></p>
              </div>

              {/* <div className="serviceItemInfo">
                <div className="serviceItem">
                  <div className="serviceItemIcon">
                    <img src={service1} className="img-fluid" alt="Service" />
                  </div>
                  <div className="serviceItemText">
                    <h5>
                      <Link to="/products">
                        Cabinet <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div className="serviceItem">
                  <div className="serviceItemIcon">
                    <img src={service2} className="img-fluid" alt="Service" />
                  </div>
                  <div className="serviceItemText">
                    <h5>
                      <Link to="/products">
                        Chair <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div className="serviceItem">
                  <div className="serviceItemIcon">
                    <img src={service3} className="img-fluid" alt="Service" />
                  </div>
                  <div className="serviceItemText">
                    <h5>
                      <Link to="/products">
                        Lamp
                        <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div className="serviceItem">
                  <div className="serviceItemIcon">
                    <img src={service4} className="img-fluid" alt="Service" />
                  </div>
                  <div className="serviceItemText">
                    <h5>
                      <Link to="/products">
                        Sofa
                        <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div className="serviceItem">
                  <div className="serviceItemIcon">
                    <img src={service5} className="img-fluid" alt="Service" />
                  </div>
                  <div className="serviceItemText">
                    <h5>
                      <Link to="/products">
                        Stool
                        <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
                <div className="serviceItem">
                  <div className="serviceItemIcon">
                    <img src={service6} className="img-fluid" alt="Service" />
                  </div>
                  <div className="serviceItemText">
                    <h5>
                      <Link to="/products">
                        Table
                        <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="row">
              {/* {isLoading && (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {error && (
                <h4 style={{ textAlign: "center", color: "red" }}>
                  Server Error
                </h4>
              )} */}
            </div>
            <div className="categoryWrapper">
              <Slider {...settings}>
                {categoriesDatas?.slice(0, 9).map((item, i) => {
                  return (
                    <div className="mediaQuaryClas" key={i}>
                      <Link to={`/product/category/${item.uid}/${item?.slug}`}>
                        <div className="serviceItemIcon">
                          <img
                            src={item?.icon?.url}
                            alt="Product"
                            className="img-fluid"
                          />
                        </div>
                        <div className="serviceItemText">
                          <h5>
                            <Link to="/products">
                              {item?.name}
                              <i className="ri-arrow-right-up-fill"></i>
                            </Link>
                          </h5>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;
