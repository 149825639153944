import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import etg from "../../assets/img/brands/etg.png";
import kynoch from "../../assets/img/brands/kynoch.png";
import falcon from "../../assets/img/brands/falcon.png";
import oemff from "../../assets/img/brands/oemff.png";
import PrivacyPolicy from "../../common/privacy-policy/PrivacyPolicy";
import { BrandImg } from "../home/proImgs/Brandimg";
import { Helmet } from "react-helmet";
import { useListAllBrandQuery } from "../products/productSlice";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { base_url } from "../../server";

function ViewAllBrand() {
  const [data, setData] = useState(null);
  const baseUrl = base_url();

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}brand/public`);
      setData(res.data);
    } catch (error) {
      console.log("Server Error");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Helmet>
        <title>Brands | Ksquare Infratech & Interior Pvt Ltd</title>
        <meta name="keyword" content="Ksquare Infratech & Interior Pvt Ltd" />
        <meta
          name="description"
          content="Ksquare Infratech & Interior Pvt Ltd"
        />
      </Helmet>
      <Breadcrumb title="All Brands" />
      <section className="brandsSec bg-white">
        {/* {isLoading && <div className="preloaderCount">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>} */}
        <div className="container">
          <div className="brandsItem fullBrandsItem">
            <div className="brandsHeader">
              <div className="fisherman-content mb-4">
                <span>our brands</span>
                <h3>top brands</h3>
              </div>
            </div>

            <ul>
              {data &&
                data.map((item, i) => {
                  return (
                    <li key={item._id}>
                      <Link to={`/product/brand/${item._id}`}>
                        <img
                          src={
                            item?.logo?.url ? item?.logo?.url : BrandImg[i]?.url
                          }
                          alt="Brand"
                          className="img-fluid"
                        />
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </section>
      <PrivacyPolicy />
    </>
  );
}

export default ViewAllBrand;
